import React from 'react'
import { Layout, ContactBanner, Footer, Section, SEO } from '../components'
import { useTranslation } from 'react-i18next'

const About = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title={t('titlePages:about')} />
      <Section title='about:title' text='about:main-text' />
      <ContactBanner />
      <Footer />
    </Layout>
  )
}

export default About
